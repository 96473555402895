import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'

import './App.sass'
import SeriesPage from './containers/SeriesPage'
import ArticlePage from './containers/ArticlePage'
import SeriesArticlesPage from './containers/SeriesArticlesPage'

import { store } from './state/store'
import ScrollToTopOnNavigation from './components/ScrollToTopOnNavigation';
import HamburgerNav from './components/HamburgerNav';
import ResourcesPage from './containers/ResourcesPage';

function NotFoundPage() {
  return (
    <h1>We could not find what you were looking for</h1>
  )
}

function App() {
  const renderRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={SeriesPage} />
        <Route exact path="/resources" component={ResourcesPage} />
        <Route exact path="/series/:seriesID" component={SeriesArticlesPage} />
        <Route exact path="/articles/:articleID" component={ArticlePage} />
        <Route exact path="/404" component={NotFoundPage} />
      </Switch>
    )
  }

  const navPages = [
    {href: '/', title: 'Home'},
    {href: '/resources', title: 'Resources' },
  ]

  const supportsHistory = 'pushState' in window.history

  return (
    <Provider store={store}>
      <Router forceRefresh={!supportsHistory}>
        <ScrollToTopOnNavigation></ScrollToTopOnNavigation>
        <div>
          <HamburgerNav pages={navPages}></HamburgerNav>

          <section className="app-body is-large">
            <div className="container">
              { renderRoutes() }
            </div>
          </section>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

function ArticleUpNext(props) {
  const currentSeriesId = useSelector((state) => state.content.currentSeries)

  const nextArticle = useSelector((state) => {
    if (!currentSeriesId) {
      return null
    }

    const series = state.content.series[currentSeriesId]

    if (!series) {
      return null
    }

    const articleIds = series.articles.map((article) => article.id)
    const currentArticleIndex = articleIds.indexOf(props.currentArticle.id)

    const nextArticleIndex = currentArticleIndex + 1
    if (nextArticleIndex <= articleIds.length - 1) {
      return series.articles[nextArticleIndex]
    }

    return null
  })

  if (!nextArticle) {
    return null
  }

  console.log('next article == ', nextArticle)

  return (
    <div className="article-up-next">
      <div>
        <h4>If you found this article helpful, you might like:</h4>
        <h4>
          <Link to={'/articles/' + nextArticle.id.toString()}>
            {nextArticle.title}
          </Link>
        </h4>
      </div>
    </div>
  )

}

export default ArticleUpNext

import { configureStore } from 'redux-starter-kit'

import { articlesReducer } from './ContentState'

export const store = configureStore({
  initialState: {},
  reducer: {
    content: articlesReducer,
  },
})

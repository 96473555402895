
function getApiBaseUrl() {
  if (process.env.NODE_ENV === 'production') {
    return 'https://app.allmentalhealth.org/api'
  }

  return 'http://localhost:3001/api'
}

function getPublicationID() {
  return process.env.REACT_APP_PUBLICATION_ID || ''
}

export const API_BASE = getApiBaseUrl()
export const PUBLICATION_ID = getPublicationID()

import { createReducer, createAction } from 'redux-starter-kit'
import axios from 'axios'

import { API_BASE, PUBLICATION_ID } from '../utils/config'

const reduceToMapByKey = (itemList, idKey) => {
  return itemList.reduce((res, item) => {
    const newEntry = {}
    newEntry[item[idKey]] = item
    return Object.assign(res, newEntry)
  }, {})
}

const contentUrl = () => {
  const baseUrl = API_BASE + '/content'

  if (PUBLICATION_ID) {
    return baseUrl + `/${PUBLICATION_ID}`
  }

  return baseUrl
}

const getContent = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(contentUrl())
      dispatch(ContentActions.cacheContent({content: response.data}))
      dispatch(ContentActions.articleFetchEnded())

    } catch {
      dispatch(ContentActions.articleFetchFailure())
    }
  }
}


export const ContentActions = {
  getContent: getContent,
  getArticle: createAction('GET_ARTICLE'),
  cacheContent: createAction("CACHE_ARTICLE"),
  beginFetchingArticle: createAction("BEGIN_FETCHING_ARTICLE"),
  articleFetchEnded: createAction("ARTICLE_FETCH_ENDED"),
  articleFetchFailure: createAction("ARTICLE_FETCH_FAILED"),
  setCurrentSeries: createAction('SET_CURRENT_SERIES'),
}

export const articlesReducer = createReducer({}, {
  [ContentActions.articleFetchEnded]: (state, action) => {
    state.fetchInProgress = false
  },
  [ContentActions.beginFetchingArticle]: (state, action) => {
    state.fetchInProgress = true
  },
  [ContentActions.cacheContent]: (state, action) => {
    const content = action.payload.content
    state.publication = content.publication
    state.articles = reduceToMapByKey(content.articles, 'id')
    state.series = reduceToMapByKey(content.series, 'id')
    state.glossaryTerms = reduceToMapByKey(content.glossary_terms, 'phrase')
  },
  [ContentActions.setCurrentSeries]: (state, action) => {
    state.currentSeries = action.payload.seriesID
  },
})

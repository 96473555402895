import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { ContentActions } from '../state/ContentState'
import ItemList from '../components/ItemList'
import LoadingSection from '../components/LoadingSection'

function renderRedirect() {
  return(
    <Redirect to={'/404'} />
  )
}

function SeriesArticlesPage({ match }) {
  const seriesId = parseInt(match.params.seriesID)
  const series = useSelector(state => state.content.series )
  const dispatch = useDispatch()

  useEffect(() => {
    if (!series) {
      dispatch(ContentActions.getContent())
    }

    dispatch(ContentActions.setCurrentSeries({seriesID: seriesId}))
  }, [dispatch, series, seriesId])

  if (!seriesId) {
    return renderRedirect()
  }

  if (!series) {
    return (
      <LoadingSection></LoadingSection>
    )
  }

  const desiredSeries = series[seriesId]

  if (!desiredSeries) {
    return renderRedirect()
  }

  const articlesForList = desiredSeries.articles.map((article) => {
    return {
      id: article.id,
      title: article.title,
      summary: article.summary,
      href: '/articles/' + article.id.toString(),
    }
  })

  return (
    <section className="section series-page">
      <div className="columns is-centered pageHeader">
        <div className="column has-text-centered">
          <h1>{desiredSeries.display_title}</h1>
        </div>
      </div>

      <ItemList items={articlesForList} />
    </section>
  )
}

export default SeriesArticlesPage

import React from 'react'

export default function LoadingSection(props) {
  return (
    <section className="section loading-container">
      <div className="columns is-centered pageHeader">
        <div className="column has-text-centered">
          <h1>Content is loading...</h1>
        </div>
      </div>
    </section>
  )

}
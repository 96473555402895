import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import marked from 'marked'

import { ContentActions } from '../state/ContentState'

function Article({ article }) {
  const [userGlossaryTerm, setUserGlossaryTerm] = useState()
  const dispatch = useDispatch()
  const glossaryData = useSelector(state => state.content.glossaryTerms)

  useEffect(() => {
    if (!glossaryData) {
      dispatch(ContentActions.getContent())
    }
  })

  useEffect(() => {
    const articleLinks = document.querySelectorAll(".article-content a")
    articleLinks.forEach((elem) => {
      elem.addEventListener('click', handleArticleLinkClick)
    })

    const unregisterHandler = () => {
      articleLinks.forEach((elem) => {
        elem.removeEventListener('click', handleArticleLinkClick)
      })
    }
    return unregisterHandler
  }, [article])

  const handleArticleLinkClick = (browserEvent) => {

    const linkURL = browserEvent.target.href

    if (linkURL.match("glossary/")) {
      browserEvent.preventDefault()
      const urlParts = linkURL.split("/")
      const term = urlParts[urlParts.length - 1];

      setUserGlossaryTerm(term)
    }
  }

  const onDefinitionClose = () => {
    setUserGlossaryTerm(undefined)
  }

  const articleContent = marked(article.content)
  return (
    <div>
      <div className="article-content" dangerouslySetInnerHTML={{ __html: articleContent }}></div>
      <GlossaryModal term={userGlossaryTerm} onDefinitionClose={onDefinitionClose} glossaryData={glossaryData}/>
    </div>
  )
}

function GlossaryModal({ term, onDefinitionClose, glossaryData }) {

  const getModalClassNames = () => {
    const defaultClasses = 'glossary modal'
    if (term) {
      return defaultClasses + ' is-active'
    }
    return defaultClasses
  }

  if(!term) {
    return <div></div>
  }

  const definition = glossaryData[term]['definition']
  if (!definition) {
    return <div></div>
  }
  const parsedDefinition = marked(definition)
  return (
    <div className={getModalClassNames()}>
      <div className="modal-background" onClick={onDefinitionClose}></div>
      <div className="modal-content">
        <div className="box">
          <p dangerouslySetInnerHTML={{ __html: parsedDefinition }}></p>
        </div>
      </div>
      <button onClick={onDefinitionClose} className="modal-close is-large" aria-label="close"></button>
    </div>
  )
}

export default Article

import React from 'react'
import { Link } from 'react-router-dom'

function Item({ item }) {

  return (
    <Link to={ item.href }>
      <div className="item card clickable-card">
        <div className="card-content">
          <h1>{item.title}</h1>
          <h3>{item.summary}</h3>
        </div>
      </div>
    </Link>
  )

}

export default Item

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Article from '../components/Article'
import { ContentActions } from '../state/ContentState'
import LoadingSection from '../components/LoadingSection'


export default function ResourcesPage(props) {
  const dispatch = useDispatch()
  const publication = useSelector((state) => state.content.publication)

  useEffect(() => {
    if (!publication) {
      dispatch(ContentActions.getContent())
    }
  })

  if (!publication) {
    return (
      <LoadingSection></LoadingSection>
    )
  }

  return (
    <section className="section article-page">
      <section>
        <div className="columns is-centered pageHeader">
          <div className="column is-half-desktop">
            <h1>Resources</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="columns is-centered pageHeader">
          <div className="column is-half-desktop">
            <Article article={ {content: publication.resources} }/>
          </div>
        </div>
      </section>
    </section>
  )

}
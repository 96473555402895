import React from 'react'

import Item from './Item'

function ItemList({ items }) {

  function renderItems() {
    return items.map((item) => {
      return (
        <div key={item.id} className="column is-one-third-widescreen is-half-desktop">
          <Item item={item} />
        </div>
      )
    })
  }

  return (
    <div className="columns is-multiline">
      {renderItems()}
    </div>
  )

}

export default ItemList
